export const checkSpecialChar = (e) => {
  var key = e.keyCode;
  if (key === 53 || key === 55) {
    e.preventDefault();
    e.stopPropagation();
  }
  // if (/[$%&*?#@^]/.test(event.key)) {
  //   event.preventDefault();
  //   event.stopPropagation();
  // }
};
export const capitalFirstWord = (e) => {
  var words = e.split(" ");
  for (var i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].substr(1);
  }

  return words.join(" ");
};
export const handleSubmit = (
  event,
  values,
  temp,
  dataGold,
  data,
  dataResepsi,
  noCatin,
  filter,
  lBarcode,
  setValidated
) => {
  const form = event.currentTarget;
  if (form.checkValidity() === true) {
    event.preventDefault();
    event.stopPropagation();
    if (dataGold === 1) {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=6281215372042&text=" +
        encodeURIComponent(`
1. Tema Undangan Video/Jpeg : ${values.temaVideo}\n
2. Paket Undangan Video/Jpeg : ${values.paketVideo}\n
3. Tema Undangan Website : ${values.temaWebsite}\n
4. Paket Undangan Website : ${values.paketWebsite}\n
5. Bahasa Undangan Website : ${values.bahasa}\n
6. Foto Undangan : ${values.foto}\n
7. Filter Instagram: ${values.pakaiFilter}
${filter}\n
8. Nama yang didahulukan : ${values.namaAwal}\n
9. Barcode : ${values.barcode}
${lBarcode}\n
10. Denah : ${values.denah}\n
11. Mempelai Wanita
-Nama Panggilan : ${values.panggilanWanita}
-Nama Lengkap : ${values.lengkapWanita}
-Nama Kedua Orang Tua : Putri ${values.wanitaAnakKe} dari Bapak ${values.namaBapakWanita} dan Ibu ${values.namaIbuWanita}\n
12. Mempelai Pria
-Nama Panggilan : ${values.panggilanPria}
-Nama Lengkap : ${values.lengkapPria}
-Nama Kedua Orang Tua : Putra ${values.priaAnakKe} dari Bapak ${values.namaBapakPria} dan Ibu ${values.namaIbuPria}\n
13. Rincian Acara
-Acara 1
-${data}
-Hari, Tanggal Bulan Tahun : ${values.hariAkad}, ${values.akad}
-Pukul ${values.pukulAkad} ${values.zonaWaktuAkad}
-Tempat acara : ${values.tempatAkad}
-Maps acara : ${values.mapsAkad}\n
-Acara 2
-${dataResepsi}
-Hari, Tanggal Bulan Tahun : ${values.hariResepsi}, ${values.resepsi}
-Pukul ${values.pukulResepsi} ${values.zonaWaktuResepsi}
-Tempat acara : ${values.tempatResepsi}
-Maps acara : ${values.mapsResepsi}\n
14. Musik : ${values.musik}\n
Data Tambahan Paket Gold\n 
1. Love Story : ${values.loveStory}\n
2. Live Streaming : ${values.live}\n
3. Wedding Gift
-Amplop Digital 1
-Nomor Rekening 1 : ${values.nomorRek}
-Nama Bank 1 : ${values.namaBank}
-Atas Nama 1 : ${values.atasNama}\n
-Amplop Digital 2
-Nomor Rekening 2 : ${values.nomorRek2}
-Nama Bank 2 : ${values.namaBank2}
-Atas Nama 2 : ${values.atasNama2}\n
-Kirim Hadiah
-Alamat : ${values.alamat}
-Nama Penerima : ${values.namaPenerima}\n
-WA Konfirmasi Amplop/Penerima : ${values.waKonfirmasi}\n
4. Reservasi Kehadiran via WA : ${values.daftarHadir} 
${noCatin}`);
    } else {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=6281215372042&text=" +
        encodeURIComponent(`
1. Tema Undangan Video/Jpeg : ${values.temaVideo}\n
2. Paket Undangan Video/Jpeg : ${values.paketVideo}\n
3. Tema Undangan Website : ${values.temaWebsite}\n
4. Paket Undangan Website : ${values.paketWebsite}\n
5. Bahasa Undangan Website : ${values.bahasa}\n
6. Foto Undangan : ${values.foto}\n
7. Filter Instagram: ${values.pakaiFilter}
${filter}\n
8. Nama yang didahulukan : ${values.namaAwal}\n
9. Barcode : ${values.barcode}
${lBarcode}\n
10. Denah : ${values.denah}\n
11. Mempelai Wanita
-Nama Panggilan : ${values.panggilanWanita}
-Nama Lengkap : ${values.lengkapWanita}
-Nama Kedua Orang Tua : Putri ${values.wanitaAnakKe} dari Bapak ${values.namaBapakWanita} dan Ibu ${values.namaIbuWanita}\n
12. Mempelai Pria
-Nama Panggilan : ${values.panggilanPria}
-Nama Lengkap : ${values.lengkapPria}
-Nama Kedua Orang Tua : Putra ${values.priaAnakKe} dari Bapak ${values.namaBapakPria} dan Ibu ${values.namaIbuPria}\n
13. Rincian Acara
-Acara 1
-${data}
-Hari, Tanggal Bulan Tahun : ${values.hariAkad}, ${values.akad}
-Pukul ${values.pukulAkad} ${values.zonaWaktuAkad}
-Tempat acara : ${values.tempatAkad}
-Maps acara : ${values.mapsAkad}\n
-Acara 2
-${dataResepsi}
-Hari, Tanggal Bulan Tahun : ${values.hariResepsi}, ${values.resepsi}
-Pukul ${values.pukulResepsi} ${values.zonaWaktuResepsi}
-Tempat acara : ${values.tempatResepsi}
-Maps acara : ${values.mapsResepsi}\n
14. Musik : ${values.musik}`);
    }
  }
  // consolevent.log(dataGold);
  event.preventDefault();
  event.stopPropagation();
  setValidated(true);
};
export const handleFormInput = (
  event,
  values,
  temp,
  filter,
  visible,
  dataGold,
  data,
  dataResepsi,
  noCatin,
  visibleAkad,
  visibleBarcode,
  visibleFilter,
  visibleGold,
  visibleResepsi,
  setFilter,
  setValues,
  setVisibleAkad,
  setVisibleBarcode,
  setVisibleResepsi,
  setVisibleGold,
  setVisible,
  setVisibleFilter,
  setDataGold,
  setDataResepsi,
  setData,
  setNoCatin,
  setTemp,
  lBarcode,
  setLBarcode
) => {
  const { name, value } = event.target;
  if (event.target.name === "daftarHadir" && event.target.value === "Iya") {
    setVisible(!visible);
  } else if (
    event.target.name === "daftarHadir" &&
    event.target.value === "Tidak"
  ) {
    setVisible(false);
    setNoCatin("");
  }
  if (event.target.name === "pakaiFilter" && event.target.value === "Iya") {
    setVisibleFilter(!visible);
  } else if (
    event.target.name === "pakaiFilter" &&
    event.target.value === "Tidak"
  ) {
    setVisibleFilter(false);
  }
  if (event.target.name === "barcode" && event.target.value === "Iya") {
    setVisibleBarcode(!visible);
  } else if (
    event.target.name === "barcode" &&
    event.target.value === "Tidak"
  ) {
    setVisibleBarcode(false);
    setLBarcode("");
  }
  if (event.target.name === "paketWebsite" && event.target.value === "Gold") {
    setVisibleGold(!visible);
    setDataGold(1);
  } else if (
    event.target.name === "paketWebsite" &&
    (event.target.value === "Silver" || event.target.value === "Bronze")
  ) {
    setVisibleGold(false);
    setDataGold(0);
  }
  if (
    event.target.name === "namaAcaraAkad" &&
    event.target.value === "Lainnya"
  ) {
    setVisibleAkad(!visibleAkad);
  } else if (
    event.target.name === "namaAcaraAkad" &&
    (event.target.value === "Akad" || event.target.value === "Pemberkatan")
  ) {
    setVisibleAkad(false);
  }
  if (
    event.target.name === "namaAcaraResepsi" &&
    event.target.value === "Lainnya"
  ) {
    setVisibleResepsi(!visibleResepsi);
  } else if (
    event.target.name === "namaAcaraResepsi" &&
    (event.target.value === "Resepsi" ||
      event.target.value === "Walimatul Ursy")
  ) {
    setVisibleResepsi(false);
  }
  if (!!values.lainnyaAkad) {
    setData("Acara : " + values.lainnyaAkad);
  } else {
    setData("Acara : " + values.namaAcaraAkad);
  }
  if (!!values.lainnyaResepsi) {
    setDataResepsi("Acara : " + values.lainnyaResepsi);
  } else {
    setDataResepsi("Acara : " + values.namaAcaraResepsi);
  }
  if (event.target.name === "nomorCatin") {
    setNoCatin("-Nomor Calon Pengantin : " + event.target.value);
  }
  if (!!values.linkBarcode) {
    setLBarcode("-Link Barcode : " + values.linkBarcode);
  }
  if (dataGold === 1) {
    setTemp(encodeURI(values.loveStory));
    setValues({
      ...values,
      [name]: value,
    });
  }
  if (!!values.filterig) {
    setFilter(
      `-Tema Filter Instagram : ${values.filterig}
-Frame : ${values.frame}`
    );
  }
  setValues({
    ...values,
    [name]: value,
  });
};
